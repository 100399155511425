var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"height":"auto","fixed-header":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{staticClass:"pr-5",attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand pr-3",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye pt-5"})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("table.detail"))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.isButtonPDF)?{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-file-pdf-o"})])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]),(!_vm.isButtonPDF && !_vm.isDetail)?_c('div',[_vm._v(" - ")]):_vm._e()]}},{key:"item.statusName",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('ChipTableComponent',{attrs:{"status":item.status,"statusName":item.statusName}})]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"m-1",attrs:{"color":"green","dark":""}},[_vm._v(" Activo ")])]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }