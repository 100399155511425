<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-8 col-lg-8">
            <h4 class="page-title">{{ $t(title) }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("shipments.home") }}</a>
                </li>

                <li class="breadcrumb-item">
                  <a href="/envios">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- End Breadcrumbbar -->
      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card m-b-30">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h5 class="card-title mb-0">
                      <i class="ti-pin-alt mr-2"></i
                      >{{ $t("shipments.shipments_title_pending") }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <AlertMessageComponent ref="alertMessageComponent" />
                </div>
                <!-- Card de los elementos de ENVIOS -->
                <CardShipmentsComponent
                  ref="cardShipmentPicking"
                  :status="4"
                  v-on:getDetailOrder="getDetailOrder"
                />
                <!-- Card de los elementos de ENVIOS -->
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-12">
            <div class="card m-b-30">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="ti-truck mr-2"></i
                      >{{ $t("shipments.shipments_title_transit") }}
                    </h5>
                  </div>
                </div>
              </div>
              <ShipmentsTableComponent
                ref="tableShipmentHistory"
                :headers="shipmentsHeaders"
                :isButton="true"
                v-on:viewRecord="getDetailOrder"
                v-on:downloadPDF="downloadPDF"
                :isDetail="false"
                :isButtonPDF="
                  withPermissionName(permissions.SHIP_SHIP_DL_PDF_02)
                "
                v-on:excelTable="excelTable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="dialogShipment"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i :class="shipmentIcon(currentItemPicking.pickingStatus)"></i>
              {{ shipmentTitle(currentItemPicking.pickingStatus) }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cleanModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="container">
                <div class="row">
                  <AlertMessageComponent ref="alertMessageComponentModal" />
                  <div class="col-md-12 pb-0 pt-7 ml-1">
                    <p class="pt-3">
                      <i
                        class="fa fa-folder-open-o mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> {{ $t("title.title_info_tras") }} </span>
                      </strong>
                    </p>
                  </div>
                  <div class="col-12 pt-0">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="generateTransfer">
                        <div class="row">
                          <div class="col-md-12 pb-0">
                            <div class="card border-light">
                              <div class="card-header card-header-detail">
                                <i
                                  class="
                                    fa fa-building-o
                                    mr-2
                                    text-fmedical-blue
                                  "
                                ></i>
                                <strong>
                                  <span>
                                    {{ $t("picking.destination_branch") }}
                                  </span>
                                </strong>
                                {{
                                  currentItemPicking.branchOfficeDestination
                                    .name
                                }}
                              </div>
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-xs-12 col-md-4">
                                    <p class="pt-3">
                                      <i
                                        class="
                                          fa fa-id-card-o
                                          mr-2
                                          text-fmedical-blue
                                        "
                                      ></i>
                                      <strong>
                                        <span> {{ $t("picking.clues") }}</span>
                                      </strong>
                                      {{
                                        currentItemPicking
                                          .branchOfficeDestination
                                          .branchOfficeKey
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-xs-12 col-md-4">
                                    <p class="pt-3">
                                      <i
                                        class="ti-truck mr-2 text-fmedical-blue"
                                      ></i>
                                      <strong>
                                        <span>
                                          {{ $t("picking.shipping_id") }}</span
                                        >
                                      </strong>
                                      {{ currentItemPicking.idShipments }}
                                    </p>
                                  </div>
                                  <div class="col-xs-12 col-md-4">
                                    <p class="pt-3">
                                      <i
                                        class="
                                          ti-pin-alt
                                          mr-2
                                          text-fmedical-blue
                                        "
                                      ></i>
                                      <strong>
                                        <span>
                                          {{ $t("picking.order_id") }}</span
                                        >
                                      </strong>
                                      {{ currentItemPicking.idOrder }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 pb-0 ml-1">
                            <p class="pt-2 pb-0">
                              <i
                                class="fa fa-id-card-o mr-2 text-fmedical-blue"
                              ></i>
                              <strong>
                                <span> {{ $t("title.title_info_") }} </span>
                              </strong>
                            </p>
                          </div>
                          <div class="col-md-12 pt-0 pb-0">
                            <validation-provider
                              v-slot="{ errors }"
                              name="drivers_name"
                              rules="required|max:50|alpha_spaces"
                            >
                              <v-text-field
                                v-model="modelShipment.driver"
                                :counter="50"
                                :error-messages="errors"
                                :label="$t('label.drivers_name')"
                                :disabled="sending || currentItemPicking.isView"
                                required
                                oninput="this.value = this.value.toUpperCase()"
                              ></v-text-field>
                            </validation-provider>
                          </div>

                          <div class="col-md-4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="plate_number"
                              rules="required|max:20"
                            >
                              <v-text-field
                                v-model="modelShipment.licensePlate"
                                :counter="20"
                                :error-messages="errors"
                                :label="$t('label.plate_number')"
                                :disabled="sending || currentItemPicking.isView"
                                required
                                oninput="this.value = this.value.toUpperCase()"
                              ></v-text-field>
                            </validation-provider>
                          </div>

                          <div class="col-md-4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="economic_number"
                              rules="required|max:10|numeric"
                            >
                              <v-text-field
                                v-model="modelShipment.number"
                                :counter="10"
                                :error-messages="errors"
                                :label="$t('label.economic_number')"
                                :disabled="sending || currentItemPicking.isView"
                                required
                              ></v-text-field>
                            </validation-provider>
                          </div>

                          <div class="col-md-4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="tag"
                              rules="required|max:20"
                            >
                              <v-text-field
                                v-model="modelShipment.tag"
                                :counter="20"
                                :error-messages="errors"
                                :label="$t('label.tag')"
                                :disabled="sending || currentItemPicking.isView"
                                required
                                oninput="this.value = this.value.toUpperCase()"
                              ></v-text-field>
                            </validation-provider>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 text-right pt-10">
                            <button
                              v-if="!currentItemPicking.isView"
                              class="btn btn-outline-success hidden-print"
                              type="submit"
                              :disabled="sending || invalid"
                            >
                              <i class="ti-location-pin mr-2"></i>
                              {{ $t("global.generate_transfer") }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
              <ShipmentsTableComponent
                ref="tableShipmentOrder"
                :headers="shipmentOrderHeaders"
              />
            </div>
          </div>
          <div class="row mt-8"></div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";
import { ShipmentsRequest,OrderStockRequest, ExcelRequest } from "@/core/request";
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ShipmentsTableComponent from "@/components/shipments/ShipmentsTableComponent";
import CardShipmentsComponent from "@/common/card/CardShipmentsComponent";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  PICKING_ORDER,
  SHIPMENTS_HISTORIC,
} from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "shipments.shipments",
      /* Cabeceras */
      shipmentsHeaders: SHIPMENTS_HISTORIC(),
      shipmentOrderHeaders: PICKING_ORDER(),
      /* General */
      idUser: "",
      itemOrigin: "",
      sending: false,
      loading: null,
      dialogShipment: false,
      currentItemPicking: {
        branchOfficeDestination: {},
        shipment: {},
        isView: false,
      },
      modelShipment: {
        driver: "",
        licensePlate: "",
        number: 0,
        tag: "",
      },
      /* Listas de los envios */
      shipmentsList: [],
      shipmentsTrList: [],
      permissions: PermissionConstants,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
  },
  methods: {
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    alertModal(type, message) {
      this.$refs.alertMessageComponentModal.reloadComponent(type, message);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    /* General */
    async updateItemOrigin(data) {
      this.itemOrigin = data;
      await this.listAll();
    },
    shipmentColor(type) {
      let classColor = "";
      switch (type) {
        case 1:
          classColor = " border-primary ";
          break;
        case 2:
          classColor = " border-primary ";
          break;
        case 3:
          classColor = " border-info ";
          break;
        default:
          break;
      }
      return classColor;
    },
    shipmentTagColor(type) {
      let classColor = "";
      switch (type) {
        case 1:
          classColor = " text-primary ";
          break;
        case 2:
          classColor = " text-primary ";
          break;
        case 3:
          classColor = " text-info ";
          break;
        default:
          break;
      }
      return classColor;
    },
    shipmentIcon(type) {
      let icon = "";
      switch (type) {
        case 4:
          icon = " ti-pin-alt ";
          break;
        case 5:
          icon = " ti-truck ";
          break;
        default:
          break;
      }
      return icon;
    },
    shipmentTitle(type) {
      let title = "";
      switch (type) {
        case 4:
          title = " Envíos pendientes de traslado";
          break;
        case 5:
          title = " Envíos en tránsito ";
          break;

        default:
          break;
      }
      return title;
    },
    cleanModal() {
      this.dialogShipment = false;
      this.$refs.tableShipmentOrder.reloadComponentClean();
      this.$refs.alertMessageComponentModal.reloadComponentClose();

      this.modelShipment.driver = "";
      this.modelShipment.licensePlate = "";
      this.modelShipment.number = "";
      this.modelShipment.tag = "";

      this.$refs.observer.reset();
    },
    /* Envios */
    //Recupero el detalle de los envios y los que se encuentran en transito
    async listAll() {
      this.loading = UtilFront.getLoadding();
      await ShipmentsRequest.findAllShipmentsByStatusShipping({
        idBranchOffice: this.itemOrigin,
        idUser: UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            //Guardo los envios en estatus enviado
            this.$refs.cardShipmentPicking.reloadComponent(
              data.shipmentStatusShipping
            );
            this.shipmentsList = data.shipmentStatusShipping;
            this.$refs.tableShipmentHistory.reloadComponent(
              data.shipmentStatusTransit
            );
            this.shipmentsTrList = data.shipmentStatusTransit;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    //Recupero el detalle de la orden
    getDetailOrder(item, index, status) {
      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      //Abro el modal
      this.dialogShipment = true;
      //Almaceno el item actual
      this.currentItemPicking = item;
      //Set la variable de status del picking
      this.currentItemPicking.pickingStatus = status;

      //Si el status es en transito
      if (status == 5) {
        this.modelShipment.driver = item.driver;
        this.modelShipment.licensePlate = item.licensePlate;
        this.modelShipment.number = item.number;
        this.modelShipment.tag = item.tag;

        this.currentItemPicking.isView = true;
      }

      //Set index
      this.currentItemPicking.index = index;

      OrderStockRequest.orderStockDetail({
        idOrder: item.idOrder,
        status: [1]
      }).then((response) => {
          let { status, data } = response;

          if (status==200) {
            /*  this.$refs.cardShipmentPicking.reloadComponent(
              this.shipmentPickingList
            ); */
            this.$refs.tableShipmentOrder.reloadComponent(data);
          } else if ( status == 202 ) {
            this.alertModal("error", data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertModal("internal");
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    generateTransfer() {
      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      //Abro el modal
      this.dialogShipment = true;

      //Modelo
      this.modelShipment.idShipments = this.currentItemPicking.idShipments;
      this.modelShipment.userBranchOffice = {
        idUser: this.idUser,
        idBranchOffice: this.itemOrigin,
      };
      ShipmentsRequest.shipmentGenerateTransfer(this.modelShipment)
        .then((response) => {
          let { success, message, data } = response.data.data;

          if (success) {
            //Elimino de Picking
            // this.shipmentsList.splice(this.currentItemPicking.index, 1);

            this.shipmentsList = this.shipmentsList.filter((picking) => {
              if (picking.idShipments !== this.currentItemPicking.idShipments)
                return picking;
            });

            this.currentItemPicking.status = 5;
            this.currentItemPicking.statusName = "Tránsito";

            //Agrego a la lista de traslados
            this.shipmentsTrList.push(this.currentItemPicking);
            //Muevo el picking a validado
            this.$refs.cardShipmentPicking.reloadComponent(this.shipmentsList);
            this.$refs.tableShipmentOrder.reloadComponent(this.shipmentsTrList);
            //Limpio el modal
            this.cleanModal();
          } else {
            this.alertModal("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertModal("internal");
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      let send =
        "idOrder=" + item.idOrder + "&" + "idShipment=" + item.idShipments;
      ExcelRequest.reportOrderPdf(send);
      this.$toast.success("Generando documento PDF");
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportShipmentsTransit({
        idBranchOffice: this.itemOrigin,
      });
    },
  },
  created() {
    //Recupero el usuario
    this.idUser = UtilFront.getDataUser().idUser;

    /*  Asigan el valor del state para sucursal global */
    this.updateItemOrigin(this.branchOfficeState.idBranchoffice);
    this.updateAction(1);
  },
  watch: {
    branchOfficeState() {
      this.updateItemOrigin(this.branchOfficeState.idBranchoffice);
    },
    "$i18n.locale": function() {
      this.shipmentOrderHeaders = PICKING_ORDER();
      this.shipmentsHeaders = SHIPMENTS_HISTORIC();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectBranchOffice,
    AlertMessageComponent,
    ShipmentsTableComponent,
    CardShipmentsComponent,
  },
};
</script>

<style>
/*Código para modificar los botones */
@media (max-width: 576px) {
  .controlSize {
    text-align: center;
    float: right;
  }

  /*Código para modificar las barras de fecha o botones de busqueda*/
  .selectSize {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .controlSize {
    float: right;
  }
}
</style>
