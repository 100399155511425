<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
              class="pr-5"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <v-tooltip v-if="isDetail" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand pr-3"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye pt-5"></i>
            </span>
          </template>
          <span> {{ $t("table.detail") }} </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-if="isButtonPDF" v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>
        <div v-if="!isButtonPDF && !isDetail">
          -
        </div>
      </template>
      <template v-slot:[`item.statusName`]="{ item, index }">
        <ChipTableComponent
          :status="item.status"
          :statusName="item.statusName"
        />
      </template>
      <template v-slot:[`item.status`]="{ item }">
          <v-chip color="green" dark class="m-1">
              Activo
          </v-chip>
      </template>
    </v-data-table>
    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import ChipTableComponent from "@/common/table/ChipTableComponent";

export default {
  props: {
    headers: {},
    isButton: false,
    showSelect: false,
    pickingStatus: 0,
    isDetail: false,
    isButtonPDF: false,
  },
  data() {
    return {
      search: "",
      dataList: [],
    };
  },
  components: {
    ButtonTableComponent,
    ChipTableComponent,
  },
  methods: {
    /* General */
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    reloadComponentClean() {
      this.dataList = [];
    },
    deleteRecord(item) {
      this.$emit("deleteRecord", item);
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
    viewRecord(item) {
      this.$emit("viewRecord", item, 0, item.status);
    },
    detail(item) {
      this.$emit("detail", item);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    excelTable() {
      this.$emit("excelTable");
    },
    itemRowBackground: function(item, index) {
      return item.status == 7 ? "style-2" : "";
    },
  },
  created() {},
};
</script>

<style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}
</style>
